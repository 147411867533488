<template>
  <div class="filter-panel">
    <div class="header">
      FILTER
    </div>
    <div class="content">
      <div class="row">
        <p-date-field
          :value="filters.importedDate"
          :error="dateError"
          label="Imported date"
          :contracts="{ eventsources: [] }"
          @change="$e => onChange($e, 'importedDate')"
        />
      </div>
      <div class="row">
        <p-date-field :value="filters.fipCreationDate" label="FIP Creation date" :error="dateError" @change="$e => onChange($e, 'fipCreationDate')" />
      </div>
      <div class="row">
        <p-multiselect
          :value="filters.milestoneExists"
          :options="['With milestones', 'No milestones']"
          label="Milestone exists"
          @input="$e => onChange($e, 'milestoneExists')"
        />
        <p-button v-if="filters.milestoneExists" class="clear" @click="onChange(null, 'milestoneExists')"><p-icon name="close"/></p-button>
      </div>
      <div class="row">
        <p-multiselect
          :value="filters.responsibleAttorneys"
          placeholder="Start typing to insert assignee from list"
          :multiple="true"
          :search-change-callback="suggestMember"
          label="Responsible attorneys"
          @input="$e => onChange($e, 'responsibleAttorneys')"
        />
      </div>
      <div class="row">
        <p-multiselect
          :value="filters.sort"
          :options="['Due Date', 'Import Date', 'Matter number']"
          label="Sort by"
          @input="$e => onChange($e, 'sort')"
        />
      </div>
      <div class="row">
        <p-multiselect :value="filters.direction" :options="['ASC', 'DESC']" label="Sort direction" @input="$e => onChange($e, 'direction')" />
      </div>
    </div>
  </div>
</template>
<script>
import httpClient from '@/utils/httpClient';

import DateField from '@/components/common/DateField';
import Multiselect from '@/components/common/Multiselect';
import Icon from '@/components/common/MdIcon';
import Button from '@/components/common/Button';

export default {
  components: {
    'p-date-field': DateField,
    'p-multiselect': Multiselect,
    'p-button': Button,
    'p-icon': Icon
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dateError: false
    };
  },
  methods: {
    onChange(value, field) {
      const newValue = {
        ...this.filters,
        [field]: value
      };

      if (!newValue.fipCreationDate && !newValue.importedDate) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
      this.$emit('filtersChanged', { field, value });
    },
    async suggestMember(s) {
      const result = await httpClient.get('/api/auth/suggest/users?q=' + s);
      return [s, ...result];
    }
  }
};
</script>
<style lang="scss" scoped>
.filter-panel {
  background-color: var(--theme-surface);
  overflow: hidden;
  height: 100%;

  .header {
    border-bottom: 1px solid var(--theme-on-background-dark);
    padding: 10px;
  }
  .content {
    padding: 10px;
  }
  .row {
    margin-bottom: 20px;
    position: relative;
  }
  .clear {
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: 10px;
  }
}
</style>
