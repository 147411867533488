<template>
  <div class="table" :class="{ preview: preview }">
    <div class="table-wrapper">
      <template v-if="collection.length">
        <div class="toggle-wrapper">
          <hub-button color="primary" @click="$emit('toggleExportMode')">{{ isExportMode ? 'View' : 'Export' }} mode</hub-button>
        </div>
        <template v-if="isExportMode">
          <div class="event-list-wrapper">
            <div class="header-wrapper">
              <div class="multiselect-wrapper">
                <Multiselect v-if="allVariables.length" v-model="selectedHeaders" label="Columns" :options="allVariables" :multiple="true" />
              </div>
            </div>
            <div class="data-table-wrapper">
              <div class="controls-wrapper">
                <div><label>Total:</label>&nbsp;{{ collection.length }}</div>
                <hub-button color="secondary" @click="copyToClipboard">Copy to clipboard</hub-button>
              </div>
              <table class="data-table">
                <tr>
                  <th v-for="header in filteredHeaders" :key="header">
                    {{ header | decamelise }}
                  </th>
                </tr>

                <tr v-for="entry in collection" :key="entry.id" class="event-list-item">
                  <td v-for="header in filteredHeaders" :key="`${header}_${entry._id}`">
                    <template v-if="header === 'Docket No.'">{{ extractMatterNumber(entry) }}</template>
                    <template v-if="header === 'Aplication No.'">{{ extractAplicationNumber(entry) }}</template>
                    <template v-if="header === 'Action due'">{{ entry.payload.next.activity.name }}</template>

                    <span v-else>{{ entry.milestones && entry.milestones[0] ? entry.milestones[0].variables[header] : '' }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="row in collection" :key="row.id" class="row">
            <milestones-table-row :row="row" @infoclicked="onInfoClicked(row)" @milestoneCreated="$emit('milestoneCreated')" />
          </div>
        </template>
      </template>
      <div v-else class="empty">
        No events matching your filters
      </div>
    </div>
    <div class="preview-wrapper">
      <div class="close-holder">
        <hub-button @click="$emit('preview', null)"><hub-icon name="close"/></hub-button>
      </div>
      <iframe v-if="preview" :src="previewUrl"></iframe>
    </div>
  </div>
</template>
<script>
import Row from './Row.vue';
import Button from '@/components/common/Button';
import Icon from '@/components/common/MdIcon';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'milestones-table-row': Row,
    'hub-button': Button,
    'hub-icon': Icon,
    Multiselect
  },
  props: {
    preview: {
      type: String,
      default: null
    },
    isExportMode: {
      type: Boolean
    },
    collection: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let allVariables = [];
    if (this.collection.length) {
      const variablesSet = this.collection.reduce((acc, curr) => {
        if (curr.milestones && curr.milestones.length) {
          Object.keys(curr.milestones[0].variables).forEach(v => acc.add(v));
        }
        return acc;
      }, new Set());
      allVariables = Array.from(variablesSet);
    }
    return {
      allVariables,
      headers: ['Docket No.', 'Aplication No.', 'Action due', ...allVariables],
      selectedHeaders: []
    };
  },
  computed: {
    host() {
      return window.location.origin.replace('backoffice.', '');
    },
    previewUrl() {
      return `${this.host}/inventions/${this.preview}?previewMode=true`;
    },
    filteredHeaders() {
      const filtered = this.allVariables.filter(v => this.selectedHeaders.includes(v));
      return ['Docket No.', 'Aplication No.', 'Action due', ...filtered];
    }
  },
  methods: {
    onInfoClicked(row) {
      this.$emit('preview', row.inventionId);
    },
    extractMatterNumber(entry) {
      const references = entry.payload?.next?.invention?.references;
      if (references) {
        const reference = references.find(r => r.startsWith('HARRITY:')) || '';
        return reference.replace('HARRITY:', '');
      }
      return '';
    },
    extractAplicationNumber(entry) {
      const references = entry.payload?.next?.invention?.references;
      if (references) {
        const reference = references.find(r => r.startsWith('US:') && r.includes('/')) || '';
        return reference.replace('US:', '');
      }
      return '';
    },
    copyToClipboard() {
      const range = document.createRange();
      const sel = window.getSelection();
      sel.removeAllRanges();
      range.selectNodeContents(document.querySelector('table'));
      sel.addRange(range);

      document.execCommand('Copy');
      sel.removeAllRanges();
    }
  }
};
</script>
<style lang="scss" scoped>
.table {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-gap: 10px;
  &.preview {
    grid-template-columns: 2fr 3fr;
  }
  .table-wrapper {
    height: 100%;
    overflow-y: scroll;
    .toggle-wrapper {
      margin-bottom: 20px;
    }
  }
  .preview-wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;
    .close-holder {
      display: flex;
      justify-content: flex-end;
    }
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }

  .event-list-wrapper {
    display: grid;
    grid-template-rows: max-content max-content minmax(0, 1fr);
    gap: 20px;

    .header-wrapper {
      display: grid;
      justify-content: flex-end;
      align-items: center;
      grid-template-columns: 1fr;
      font-size: 0.75rem;
      gap: 20px;
      margin: 0;
      padding: 0;
      padding-right: 10px;
      font-weight: 400;

      label {
        font-weight: 500;
      }

      > * {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
    .data-table-wrapper {
      width: 100%;
      overflow-x: scroll;
      .controls-wrapper {
        display: grid;
        grid-template-columns: max-content max-content;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
      }
      .data-table {
        border: 1px solid var(--theme-highlight);
        table-layout: fixed;
        td,
        th {
          border: 1px solid var(--theme-highlight);
          padding: 3px 10px;
          max-width: 200px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
