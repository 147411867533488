<template>
  <p-list v-if="$hasPermission('docketing_tasks.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import List from '../components/milestones/List.vue';

export default {
  components: {
    'p-list': List,
    'p-not-found': NotFound
  }
};
</script>
