<template>
  <div class="milestones-wrapper" :class="{ collapse: !filterExpanded }">
    <div class="filter-wrapper">
      <p-milestones-filter :filters="filters" @filtersChanged="onFiltersChanged" />
      <hub-button class="expand-button" @click="filterExpanded = !filterExpanded">
        <hub-icon :name="filterExpanded ? 'chevron-triple-left' : 'filter-outline'" />
      </hub-button>
    </div>
    <p-milestones-table
      v-if="!isRequestPending"
      :is-export-mode="isExportMode"
      :collection="collection"
      :preview="preview"
      @preview="onPreview"
      @milestoneCreated="fetchData"
      @toggleExportMode="toggleExportMode"
    />
    <p-loading v-else />
  </div>
</template>
<script>
import { format, parse } from 'date-fns';
import { mapState } from 'vuex';

import Filter from './Filter.vue';
import Table from './Table.vue';
import Icon from '@/components/common/MdIcon';
import Button from '@/components/common/Button';
import Loading from '@/components/common/Loading';

const sortMapping = {
  'Due Date': 'dueDate',
  'Import Date': 'importDate',
  'Matter number': 'matterNumber'
};

const directionMapping = {
  ASC: 1,
  DESC: -1
};
export default {
  components: {
    'p-milestones-filter': Filter,
    'p-milestones-table': Table,
    'hub-button': Button,
    'hub-icon': Icon,
    'p-loading': Loading
  },
  data() {
    return {
      filterExpanded: true,
      preview: null,
      isExportMode: this.$route.query.isExportMode === 'true',
      filters: {
        importedDate: this.$route.query.importedDate || format(new Date(), 'MMddyyyy'),
        fipCreationDate: this.$route.query.fipCreationDate ? format(this.$route.query.fipCreationDate, 'MMddyyyy') : null,
        milestoneExists: this.$route.query.milestoneExists,
        responsibleAttorneys: this.$route.query.responsibleAttorneys || null,
        sort: this.$route.query.sort || 'Matter number',
        direction: this.$route.query.direction || 'DESC'
      }
    };
  },
  computed: {
    ...mapState({
      collection: s => s.docketingTasks.collection,
      isRequestPending: s => s.docketingTasks.isGetCollectionRequestPending
    })
  },
  created() {
    this.fetchData();
  },
  methods: {
    onFiltersChanged({ field, value }) {
      if (this.filters[field] === value) {
        return;
      }
      this.filters[field] = value;
      this.preview = null;
      if (this.filters.fipCreationDate || this.filters.importedDate) {
        this.fetchData();
      }
      this.$router.push({
        path: this.$route.path,
        query: { ...this.filters, isExportMode: this.isExportMode }
      });
    },
    onPreview(v) {
      this.preview = v;
    },
    toggleExportMode() {
      this.isExportMode = !this.isExportMode;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.filters, isExportMode: this.isExportMode }
      });
    },
    fetchData() {
      const filters = {};
      if (this.filters.importedDate) {
        filters.importedDate = parse(this.filters.importedDate, 'MMddyyyy', new Date());
      }
      if (this.filters.fipCreationDate) {
        filters.fipCreationDate = parse(this.filters.fipCreationDate, 'MMddyyyy', new Date());
      }
      if (this.filters.milestoneExists) {
        filters.milestoneExists = this.filters.milestoneExists === 'With milestones';
      }
      if (this.filters.responsibleAttorneys && this.filters.responsibleAttorneys.length) {
        filters.responsibleAttorneys = this.filters.responsibleAttorneys;
      }
      if (this.filters.sort) {
        filters.sort = sortMapping[this.filters.sort];
      }
      if (this.filters.direction) {
        filters.direction = directionMapping[this.filters.direction];
      }
      this.$store.dispatch('docketingTasks/getCollection', filters);
    }
  }
};
</script>
<style lang="scss" scoped>
.milestones-wrapper {
  width: 100%;
  padding: 0 10px 10px;
  display: grid;
  grid-template-columns: 350px 1fr;
  grid-gap: 50px;
  &.collapse {
    grid-template-columns: 0 1fr;
  }
  .filter-wrapper {
    position: relative;
    .expand-button {
      position: absolute;
      background-color: var(--theme-surface);
      top: 0;
      right: -45px;
    }
  }
}
</style>
